"use strict";

// Utilityes

const STRAPI_URL = `https://acd-san-stino.herokuapp.com`;

const primaSquadra = document.querySelector("#prima-squadra");
const allievi = document.querySelector("#allievi");
const giovanissimi = document.querySelector("#giovanissimi");
const esordienti = document.querySelector("#esordienti");
const pulcini = document.querySelector("#pulcini");
const primiCalci = document.querySelector("#primi-calci");

const getSquadra = async function (squadra, api, id = 0) {
  const response = await fetch(
    `${STRAPI_URL}/api/${api}?populate=Foto&populate=Giocatore&sort[0]=id`
  );
  const newsJson = await response.json();
  const data = newsJson.data;
  const generateMarkup = function () {
    return /*html*/ `
    <h1 class="category--title">${data[id].attributes.Titolo}</h1>
    <h3>${
      data[id].attributes.Sottotitolo ? data[id].attributes.Sottotitolo : ""
    }</h3>
    <img src="${data[id].attributes.Foto.data.attributes.url}">
      `;
  };
  squadra.insertAdjacentHTML("afterbegin", generateMarkup());
};
